import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from './components/RTL';
import { cspUrlsConfig, gtmConfig } from './config';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import { Helmet } from 'react-helmet-async';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  const cspUrl = cspUrlsConfig.cspUrls;

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <meta
            httpEquiv="Content-Security-Policy"
            content={cspUrl}
          />
        </Helmet>
        <RTL direction={settings.direction}>
          <CssBaseline />
          <Toaster position="top-right" />
          {/* <SettingsDrawer />
          {auth.isInitialized ? content : <SplashScreen />} */}
          {content}
        </RTL>
      </>
    </ThemeProvider>
  );
};

export default App;
