export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const azureAdConfig = {
  clientId: '2438fa3a-75a8-449c-9607-5cedddd93b9f',
  authority: 'https://login.microsoftonline.com/esignatur.onmicrosoft.com',
  redirectUri: process.env.REACT_APP_AZUREAD_REDIRECT_URI,
  postLogoutRedirectUri: '/',
  navigateToLoginRequestUrl: false
};
export const azureAdRequest = {
  scopes: ['api://0f9b483c-328e-4c8e-822f-279e3cd5ac3c/SupportTool.Default']
};
export const azureAdGroups = {
  test: process.env.REACT_APP_AZUREAD_GROUP_TEST,
  support: process.env.REACT_APP_AZUREAD_GROUP_SUPPORT,
  onboarding: process.env.REACT_APP_AZUREAD_GROUP_ONBOARDING,
  branding: process.env.REACT_APP_AZUREAD_GROUP_BRANDING,
  customers: process.env.REACT_APP_AZUREAD_GROUP_CUSTOMERS,
  users: process.env.REACT_APP_AZUREAD_GROUP_USERS,
  approvers: process.env.REACT_APP_AZUREAD_GROUP_APPROVERS,
  tasks: process.env.REACT_APP_AZUREAD_GROUP_TASKS
};
export const apiConfig = {
  baseUrl: process.env.REACT_APP_API_BASE_URL
};

export const scheduleTasksSocketsConfig = {
  baseUrl: process.env.REACT_APP_SCHEDULED_TASKS_SOCKETS_BASE_URL
};

export const salesForceConfig = {
  baseUrl: process.env.REACT_APP_SALESFORCE_BASE_URL
};

export const flowmailerConfig = {
  baseUrl: process.env.REACT_APP_FLOWMAILER_BASE_URL
};

export const cspUrlsConfig = {
  cspUrls: process.env.REACT_APP_CSP
};
